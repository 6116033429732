<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>부서별 휴일관리</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="120px">
                    <col width="80px">
                    <col width="100px">
                    <col width="80px">
                    <col width="120px">
                    <col>
                </colgroup>
                <tr>
                    <th><label class="is-required">부서선택</label></th>
                    <td>
                        <v-select class="jh-form" :items="INQRY_TYP_CD_LIST" item-value="CD" item-text="CD_NM" placeholder="선택하세요" v-model="INQRY_TYP_CD" @change="initHDYList"></v-select>
                    </td>
                    <th><label class="is-required">조회연도</label></th>
                    <td>
                        <v-text-field class="jh-form" name="name" @keyup.enter="selectHDYlist" v-model="YEARS"></v-text-field>
                    </td>
                    <th><label>휴일구분</label></th>
                    <td>
                        <v-select class="jh-form" :items="HDY_GB_CD" item-value="CD" item-text="CD_NM" placeholder="선택하세요" v-model="HDY_NEWGB_CD"></v-select>
                    </td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="selectHDYlist">조회</v-btn></td>
                </tr>
            </table>
        </div>

        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>휴일목록</h2>
                <div class="is-right">
                    <v-btn class="jh-btn is-border-blue is-txt-blue" v-if="this.mixin_set_btn(this.$options.name, 'btnAddHDY')" @click="addHdy">휴일추가</v-btn>
                    <v-btn class="jh-btn is-blue" v-if="this.mixin_set_btn(this.$options.name, 'btnAddSat')" @click="addSat">토요일추가</v-btn>
                    <v-btn class="jh-btn is-red" v-if="this.mixin_set_btn(this.$options.name, 'btnAddSun')" @click="addSun">일요일추가</v-btn>
                </div>
            </div>
            <!--
            <data-tables
            v-model="SELECTHDY"
            dataTableClass="has-control"
            :data-table-options="dataTableOptions"
            @click:row="rowClick"
            :paginationOptions="paginationOptions"
            >
                <template v-slot:item.colm10>
                    <button type="button" class="jh-btn is-icon"><i class="jh-icon-circle-play is-blue"></i></button>
                </template>
            </data-tables>
            -->
            <v-data-table
              height="222px"
              dense
              :headers="gridDataHeaders"
              :items="gridDataText"
              :items-per-page="30"
              item-key="index"
              :page.sync="page"
              hide-default-footer
              class="grid-default"
              @page-count="pageCount = $event"
              @click:row="rowClick"
              v-model="SELECTHDY"
              no-data-text="검색된 휴일이 없습니다."
              fixed-header
              :item-class="isActiveRow"
            >
            </v-data-table>
            <div class="grid-paging text-center pt-2">
                <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="totalVisible"
                    next-icon="svg-paging-next"
                    prev-icon="svg-paging-prev"
                ></v-pagination>
            </div>
            <p class="caption text-right">
                <strong class="clr-error">{{ gridDataText.length }}</strong
                >건의 휴일이 검색되었습니다.
            </p>
        </div>

        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>휴일상세정보</h2>
                <div class="is-right">
                    <v-btn class="jh-btn" v-if="this.mixin_set_btn(this.$options.name, 'btnNew')" @click="newBtn">초기화</v-btn>
                    <v-btn class="jh-btn is-del" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" @click="showAlertDel">삭제</v-btn>
                    <v-btn class="jh-btn is-main" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" @click="showAlertReg">저장</v-btn>
                </div>
            </div>
            <table class="jh-tbl-detail">
                <colgroup>
                    <col width="100px">
                    <col width="200px">
                    <col width="100px">
                    <col>
                </colgroup>
                <tbody>
                    <tr>
                        <th><label class="is-required">휴일일자</label></th>
                        <td>
                            <v-menu v-model="DT_menu" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="inputDate" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="DT_menu = false"
                                  maxlength="10" @input="dateOnInput" @click:clear="HDY_DT = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="HDY_DT" @input="DT_menu = false" no-title locale="ko-KR" :day-format="mixin_getDate" @change="dateOnChange"></v-date-picker>
                            </v-menu>
                        </td>
                        <th rowspan="3"><label>휴일설명</label></th>
                        <td rowspan="3">
                            <v-textarea class="jh-form" style="height: 85px;" :rules="textareaRules" :value="textareaValue" v-model="HDY_FULL_NM"></v-textarea>
                        </td>
                    </tr>
                    <tr>
                        <th><label class="is-required">휴일명</label></th>
                        <td>
                            <v-text-field class="jh-form" name="name" value="휴일명" id="id" v-model="HDY_NM"></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <th><label class="is-required">휴일구분</label></th>
                        <td>
                            <v-select class="jh-form" s :items="HDY_DETAIL_GB_CD" item-value="CD" item-text="CD_NM" placeholder="선택하세요" v-model="HDY_GB_NM"></v-select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import api from '@/store/apiUtil.js'
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

export default {
  name: "MENU_E020601",
  mixins: [mixin],
  components: {
    dataTables
  },
  data() {
    return {
      DT_menu: false,
      menu1: false,
      menu2: false,
      inputDate: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      dropItems: [{ title: "option1" }],
      gridDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "index",
          width: "60px",
          sortable: false,
        },
        { text: '휴일일자', value: 'HOLIDAY_DT', align: 'center', width: "30%", sortable: false, },
        { text: '휴일명', value: 'HOLIDAY_NM',align: 'center', width: "30%", sortable: false, },
        { text: '휴일구분', value: 'HOLIDAY_GB_NM', align: 'center',sortable: false, },
      ],
      gridDataText: [],

       page: 1,
       pageCount: 0,
       itemsPerPage: 5,
       totalVisible: 10,

        dataTableOptions: {
            fixedHeader: true,
            hideDefaultFooter: true,
            height: '440px',
            itemKey: 'index',
            itemsPerPage: 50,
            noDataText: "조회된 결과가 없습니다.",
            page: 1,
            showSelect: true,
            itemClass: 'isActiveRow'
        },
        paginationOptions: {
            totalVisible: 10
        },

      textareaRules: [v => v.length <= 200 || 'Max 200 characters'],
      textareaValue: '200자 제한!',

      ASP_CUST_KEY:[],
      INQRY_TYP_CD_LIST:[],

      HDY_GB_CD:[
        {
          CD: '',
          CD_NM: '전체'
        }
      ],

      HDY_DETAIL_GB_CD:[],

      HDY_ID:'',

      ASP_NEWCUST_KEY:this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
      YEARS:'',
      HDY_NEWGB_CD:'',

      HDY_DT:'',
      HDY_NM:'',
      HDY_FULL_NM:'',
      HDY_GB_NM:'',
      INQRY_TYP_CD:'',

      TALK_HOLIDAY_ID: '',

      SELECTHDY:[],

      SELHDYARR:[],
     }
  },

  created() {
        this.dataTableOptions.headers = this.gridDataHeaders;
        this.dataTableOptions.items = this.gridDataText;
  },

  methods: {
    dateOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.inputDate) && moment(this.inputDate).isValid()) {
        this.HDY_DT = this.inputDate;
        // datepick 다시 랜더링
        this.DT_menu = false;
        this.DT_menu = true;
      }
    },
    dateOnChange(e) {
      this.inputDate = e;
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },

    initHDYList(){
      this.gridDataText=[]
      this.page=1;
      this.SELECTHDY=[];
      this.SELHDYARR=[];
      this.newBtn()
    },

    //조회버튼 클릭 이벤트
    initInqCdList(){      
      api.post("api/setting/inqcd/manage/list",   //api url입력
      {
        ASP_NEWCUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        REG_DEPT_CD: "X",
        REG_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        UPD_DEPT_CD: "X",
        UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        INQRY_TYP_CD: this.INQRY_TYP_CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage",
        "METHOD" : "list",
        "TYPE" : "BIZ_SERVICE",
        "ROW_CNT" : "200",
        "PAGES_CNT" : "1",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        this.INQRY_TYP_CD_LIST = response.data.DATA;

        if(this.INQRY_TYP_CD_LIST.length > 0){
          this.INQRY_TYP_CD = this.INQRY_TYP_CD_LIST[0].CD
        }
      })
      .catch((err) => {
        alert(err);
      })
    },

    //조회버튼 클릭 이벤트
    selectHDYlist(){
      if (this.INQRY_TYP_CD == "" || this.INQRY_TYP_CD == undefined) {
        this.showAlert("부서를 먼저 선택해주세요.");
        return;
      }
      if (this.YEARS == "" || this.YEARS == undefined) {
        this.showAlert("조회년도를 확인해주세요.");
        return;
      }
      api.post("api/setting/holiday/manage/list",   //api url입력
      {
        ASP_NEWCUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        SEARCH_YYYY: this.YEARS,
        SEARCH_HOLIDAY_GB_CD: this.HDY_NEWGB_CD,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        REG_DEPT_CD: "X",
        REG_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        UPD_DEPT_CD: "X",
        UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        INQRY_TYP_CD: this.INQRY_TYP_CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage",
        "METHOD" : "list",
        "TYPE" : "BIZ_SERVICE",
        "ROW_CNT" : "200",
        "PAGES_CNT" : "1",
      }})
      .then((response) => {
        console.log(response.data.DATA);
        this.gridDataText = response.data.DATA;
        for(var i=0; i<this.gridDataText.length;i++){
          this.gridDataText[i]["index"]= i+1;
        }
        this.page=1;
        this.SELECTHDY=[];
        this.SELHDYARR=[];

        if(this.gridDataText.length > 0){
          this.searchRow1();
        }
      })
      .catch((err) => {
        alert(err);
      })
    },

    //휴일 상세정보
    //row click이벤트
    rowClick (item) {

      this.HDY_ID = item.TALK_HOLIDAY_ID;
      let DT = item.HOLIDAY_DT;
      let DT_Y = DT.substr(0,4);
      let DT_M = DT.substr(4,2);
      let DT_D = DT.substr(6,2);
      this.HDY_DT = DT_Y + "-" + DT_M + "-" + DT_D;
      this.inputDate = DT_Y + "-" + DT_M + "-" + DT_D;
      //this.HDY_DT = item.HOLIDAY_DT;
      this.HDY_NM = item.HOLIDAY_NM;
      this.HDY_GB_NM = item.HOLIDAY_GB_CD;
      this.HDY_FULL_NM = item.HOLIDAY_FULL_NM;

      this.TALK_HOLIDAY_ID = item.TALK_HOLIDAY_ID;

      this.SELHDYARR[this.SELHDYARR.length] = item.TALK_HOLIDAY_ID
    },

    isActiveRow(item){
      const activeClass = item.TALK_HOLIDAY_ID === this.HDY_ID ? 'active' : '';
      return activeClass;
    },

    //휴일추가 버튼클릭 이벤트
    addHdy(){
      if (this.INQRY_TYP_CD == "" || this.INQRY_TYP_CD == undefined) {
        this.showAlert("부서를 먼저 선택해주세요.");
        return;
      }
      if (this.YEARS == "" || this.YEARS == undefined) {
        this.showAlert("조회년도를 확인해주세요.");
        return;
      }
      api.post("api/setting/holiday/manage/hldy/regist",
      {
        ASP_NEWCUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        START_YEAR: this.YEARS,
        END_YEAR: this.YEARS,
        STR_CASE: '1',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        REG_DEPT_CD: "X",
        REG_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        UPD_DEPT_CD: "X",
        UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        INQRY_TYP_CD: this.INQRY_TYP_CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage.hldy",
        "METHOD" : "regist",
        "TYPE" : "BIZ_SERVICE",
        "CALL_BACK" : "settingHolidayManage.addHoliday_callback",
        "ASYNC" : "true",
      }})
      .then((response) => {
        this.selectHDYlist();
        this.SELECTHDY=[];
        this.SELHDYARR=[];
      })
      .catch((err) => {
        alert(err);
      })
    },

    //토요일추가 버튼클릭 이벤트
    addSat(){
      if (this.INQRY_TYP_CD == "" || this.INQRY_TYP_CD == undefined) {
        this.showAlert("부서를 먼저 선택해주세요.");
        return;
      }
      if (this.YEARS == "" || this.YEARS == undefined) {
        this.showAlert("조회년도를 확인해주세요.");
        return;
      }
      api.post("api/setting/holiday/manage/wkend/regist",
      {
        ASP_NEWCUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        START_YEAR: this.YEARS,
        END_YEAR: this.YEARS,
        STR_CASE: '3',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        REG_DEPT_CD: "X",
        REG_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        UPD_DEPT_CD: "X",
        UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        INQRY_TYP_CD: this.INQRY_TYP_CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage.hldy",
        "METHOD" : "regist",
        "TYPE" : "BIZ_SERVICE",
        "CALL_BACK" : "settingHolidayManage.addHoliday_callback",
        "ASYNC" : "true",
      }})
      .then((response) => {
        this.selectHDYlist();
        this.SELECTHDY=[];
        this.SELHDYARR=[];
      })
      .catch((err) => {
        alert(err);
      })
    },

    //일요일추가 버튼클릭 이벤트
    addSun(){
      if (this.INQRY_TYP_CD == "" || this.INQRY_TYP_CD == undefined) {
        this.showAlert("부서를 먼저 선택해주세요.");
        return;
      }
      if (this.YEARS == "" || this.YEARS == undefined) {
        this.showAlert("조회년도를 확인해주세요.");
        return;
      }
      api.post("api/setting/holiday/manage/wkend/regist",   //api url입력
      {
        ASP_NEWCUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        START_YEAR: this.YEARS,
        END_YEAR: this.YEARS,
        STR_CASE: '4',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        REG_DEPT_CD: "X",
        REG_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        UPD_DEPT_CD: "X",
        UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        INQRY_TYP_CD: this.INQRY_TYP_CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage.hldy",
        "METHOD" : "regist",
        "TYPE" : "BIZ_SERVICE",
        "CALL_BACK" : "settingHolidayManage.addHoliday_callback",
        "ASYNC" : "true",
      }})
      .then((response) => {
        this.selectHDYlist();
        this.SELECTHDY=[];
        this.SELHDYARR=[];
      })
      .catch((err) => {
        alert(err);
      })
    },

    //신규버튼 클릭 이벤트
    newBtn(){
      this.HDY_ID = '';
      var today = new Date();
      var year = today.getFullYear();
      var month = ('0' + (today.getMonth() + 1)).slice(-2);
      var day = ('0' + today.getDate()).slice(-2);
      var todat_DT = year + '-' + month  + '-' + day;

      this.HDY_DT = todat_DT;
      this.HDY_NM = '휴일명';
      this.HDY_FULL_NM = '휴일설명';
      this.HDY_GB_NM = '1';
    },

    //삭제버튼 클릭 이벤트
    deleteBtn(){
      //for(let i in this.SELHDYARR){
        api.post("api/setting/holiday/manage/delete",   //api url입력
        {
          ASP_NEWCUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
          TALK_HOLIDAY_ID: this.HDY_ID,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
          REG_DEPT_CD: "X",
          REG_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          UPD_DEPT_CD: "X",
          UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          INQRY_TYP_CD: this.INQRY_TYP_CD,
        },
        {head: {
          "SERVICE" : "setting.holiday.manage",
          "METHOD" : "delete",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          this.success();
          this.selectHDYlist();
        })
        .catch((err) => {
          this.fail();
        })
      //}
    },

    afterDel(){
      this.HDY_DT = '';
      this.HDY_NM = '';
      this.HDY_FULL_NM = '';
      this.HDY_GB_NM = '';
      this.SELECTHDY=[];
      this.SELHDYARR=[];
    },

    //저장버튼 클릭 이벤트
    regBtn(){
      console.log("HDY_ID" + this.HDY_ID);
      if (this.HDY_DT == "" || this.HDY_DT == undefined) {
        this.showAlert("휴일 일자를 입력해주세요.");
        return;
      }
      if (this.HDY_NM == "" || this.HDY_NM == undefined) {
        this.showAlert("휴일명을 입력해주세요.");
        return;
      }
      if (this.HDY_GB_NM == "" || this.HDY_GB_NM == undefined) {
        this.showAlert("휴일구분을 선택해주세요.");
        return;
      }

      api.post("api/setting/holiday/manage/regist",   //api url입력
      {
        ASP_NEWCUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        HOLIDAY_DT: this.HDY_DT,
        HOLIDAY_NM: this.HDY_NM,
        HOLIDAY_GB_CD: this.HDY_GB_NM,
        HOLIDAY_DESC: this.HDY_FULL_NM,
        TALK_HOLIDAY_ID: 'HOL',
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        REG_DEPT_CD: "X",
        REG_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        UPD_DEPT_CD: "X",
        UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        INQRY_TYP_CD: this.INQRY_TYP_CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage",
        "METHOD" : "regist",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        this.selectHDYlist();
        this.HDY_DT = '';
        this.HDY_NM = '';
        this.HDY_FULL_NM = '';
        this.HDY_GB_NM = '';
        this.SELECTHDY=[];
        this.SELHDYARR=[];
        this.success();
      })
      .catch((err) => {
        this.fail();
      })

    },

    modiBtn(){
      console.log("HDY_ID" + this.HDY_ID);
      if (this.HDY_DT == "" || this.HDY_DT == undefined) {
        this.showAlert("휴일 일자를 입력해주세요.");
        return;
      }
      if (this.HDY_NM == "" || this.HDY_NM == undefined) {
        this.showAlert("휴일명을 입력해주세요.");
        return;
      }
      if (this.HDY_GB_NM == "" || this.HDY_GB_NM == undefined) {
        this.showAlert("휴일구분을 선택해주세요.");
        return;
      }
      api.post("api/setting/holiday/manage/modify",   //api url입력
      {
        ASP_NEWCUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        TALK_HOLIDAY_ID: this.HDY_ID,
        HOLIDAY_DT: this.HDY_DT,
        HOLIDAY_NM: this.HDY_NM,
        HOLIDAY_GB_CD: this.HDY_GB_NM,
        HOLIDAY_DESC: this.HDY_FULL_NM,
        ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        REG_DEPT_CD: "X",
        REG_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        UPD_DEPT_CD: "X",
        UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        PROC_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
        INQRY_TYP_CD: this.INQRY_TYP_CD,
      },
      {head: {
        "SERVICE" : "setting.holiday.manage",
        "METHOD" : "modify",
        "TYPE" : "BIZ_SERVICE",
      }})
      .then((response) => {
        this.selectHDYlist();

        this.HDY_DT = '';
        this.HDY_NM = '';
        this.HDY_FULL_NM = '';
        this.HDY_GB_NM = '';
        this.SELECTHDY=[];
        this.SELHDYARR=[];

        this.success();
      })
      .catch((err) => {
        this.fail()
      })
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    showAlertReg(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '휴일을 저장하시겠습니까?',
        iconClass: 'svg-chk-lg',
        type: 'confirm',
        callYes: this.callYes1,
        callNo: this.callNo1,
      })
    },

    callYes1() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });
      if (this.HDY_ID == '' || this.HDY_ID == undefined){
        this.regBtn();
      } else {
        this.modiBtn();
      }
    },

    callNo1(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    showAlertDel(){
      if(this.SELHDYARR.length == 0){
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: "체크된 항목이 존재하지 않습니다.",
          iconClass: "svg-error-lg",
          type: "default",
        });
        return;
      }
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '휴일을 삭제하시겠습니까?',
        iconClass: 'svg-error-lg',
        type: 'confirm',
        callYes: this.callYes2,
        callNo: this.callNo2,
      })
    },

    callYes2() {
      this.$store.commit("alertStore/openAlertDialog", {
      alertDialogToggle: false,
      });
      this.deleteBtn();
      this.afterDel();
      
    },

    callNo2(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    success(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '정상처리 되었습니다.',
        iconClass: 'svg-done-lg',
        type: 'default'
      })
    },

    fail(){
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.',
        iconClass: 'svg-error-lg',
        type: 'default'
      })
    },

    searchRow1(){
      this.rowClick(this.gridDataText[0]);
    },

  },

  async mounted(){
    let codeName = ["TALK010"];
    let common_code = await this.mixin_common_code_get_all(codeName);
    this.HDY_GB_CD = [...this.HDY_GB_CD, ...common_code[0].group_value];
    this.HDY_DETAIL_GB_CD = common_code[0].group_value;
    this.initInqCdList()
  },

  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
  },
};
</script>

<style>

</style>
